import React, { FunctionComponent } from 'react';

import styles from './IconError.module.scss';
import { IconErrorProps } from './IconError.types';

const IconError: FunctionComponent<IconErrorProps> = ({ className, fill }: IconErrorProps) => {
    return (
        <span className={`${styles.container} ${className ? className : ''}`}>
            <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12 0L0 21H24L12 0ZM13.2 17.5H10.8V15.1667H13.2V17.5ZM10.8 14V8.16667H13.2V14H10.8Z"
                    fill={fill ? fill : '#BF4F2A'}
                />
            </svg>
        </span>
    );
};

export default IconError;
