import { createSlice } from '@reduxjs/toolkit';

import { fetchConsumptionPointList } from './SecondStep.thunk';
import { ConsumptionPointState } from './SecondStep.types';

const consumptionPointListInitialState: ConsumptionPointState = {
    consumptionPointList: [],
    loading: 'idle',
};

const consumptionPointListSlice = createSlice({
    name: 'consumptionPointList',
    initialState: consumptionPointListInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchConsumptionPointList.pending, (state) => {
            state.loading = 'pending';
        });
        builder.addCase(fetchConsumptionPointList.fulfilled, (state, action) => {
            state.loading = 'succeeded';
            if (action.payload) {
                state.consumptionPointList = action.payload;
            }
        });
        builder.addCase(fetchConsumptionPointList.rejected, (state) => {
            state.loading = 'failed';
        });
    },
});

const consumptionPointListReducer = consumptionPointListSlice.reducer;

export { consumptionPointListReducer };
