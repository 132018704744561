import { ContentText, Heading, IconError, InputField, Link, Loader, spacingNumerical } from '@fortum/elemental-ui';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AutoComplete } from 'src/components/AutoComplete/AutoComplete';
import { AppDispatch } from 'src/providers/store';

import { ClientsList, ClientsListState } from './FirstStep.types';
import { fetchClientList } from './FirstStep.thunk';

import '@fortum/elemental-ui/FortumSans.css';
import styles from './FirstStep.module.scss';

type FirstStepProps = {
    clientNumber: string;
    complaintNumber: string;
    onSelectAutoCompleteItem: (value: string, name: string, address: string) => void;
    onChangeInputField: (value: string) => void;
};

const FirstStep: FunctionComponent<FirstStepProps> = ({
    clientNumber,
    complaintNumber,
    onSelectAutoCompleteItem,
    onChangeInputField,
}: FirstStepProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const clientsListState = useSelector<ClientsListState>((state) => state.clientsList) as ClientsList;

    useEffect(() => {
        dispatch(fetchClientList());
    }, []);

    return (
        <>
            <Heading
                style={{
                    marginTop: spacingNumerical.m,
                    paddingTop: spacingNumerical.xxxs,
                    marginBottom: spacingNumerical.xs,
                }}
                lineHeight={1.2}
                level={2}
                styledAs={5}
                textAlign="left"
            >
                Parametry reklamacji
            </Heading>
            {clientsListState.loading === 'pending' && (
                <div className={styles.centerContainer}>
                    <Loader />
                </div>
            )}
            {clientsListState.loading === 'succeeded' && clientsListState.clientList.length && (
                <>
                    <AutoComplete
                        name="client"
                        label="Numer i nazwa klienta"
                        emptyLabel="Klient"
                        placeholder="Wyszukaj"
                        className={styles.inputField}
                        valueProp={clientNumber}
                        items={clientsListState.clientList.map((item) => {
                            return {
                                value: item.clientNumber,
                                name: item.clientName,
                            };
                        })}
                        onSelectItem={(value) => {
                            const client = clientsListState.clientList.find((item) => item.clientNumber === value);
                            if (client) {
                                onSelectAutoCompleteItem(
                                    value,
                                    client.clientName,
                                    `${client.clientAddress.street} ${client.clientAddress.streetNumber}, ${client.clientAddress.postalCode} ${client.clientAddress.city}`,
                                );
                            }
                        }}
                        onBlur={(e) => {
                            e.target.value === '' && onSelectAutoCompleteItem('', '', '');
                        }}
                    />
                    <InputField
                        disabled={!clientNumber}
                        name="complaintNumber"
                        label="Numer reklamacji w systemie CRM"
                        className={styles.inputField}
                        onChange={(e) => {
                            onChangeInputField(e.currentTarget.value);
                        }}
                        value={complaintNumber}
                    />
                </>
            )}
            {clientsListState.loading === 'failed' && (
                <div className={styles.centerContainer}>
                    <IconError color={'#BF4F2A'} size={40} />
                    <ContentText color={'#41414A'}>Błąd wyświetlania</ContentText>
                    <ContentText color={'#777'} size={16}>
                        Nie udało się wczytać wyników &nbsp;
                        <Link size="s" onClick={() => dispatch(fetchClientList())}>
                            Spróbuj ponownie
                        </Link>
                    </ContentText>
                </div>
            )}
        </>
    );
};

export default FirstStep;
