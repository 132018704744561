import React, { FunctionComponent, PropsWithChildren } from 'react';
import { IconProps } from '@fortum/elemental-ui';
import { ReactComponent as Logout } from 'src/assets/log-out.svg';

import styles from './CustomIcon.module.scss';
export const CustomIcon: FunctionComponent<IconProps> = (props: PropsWithChildren<IconProps>) => {
    return <span className={`${styles.container} ${props.className ? props.className : ''}`}>{props.children}</span>;
};

export const LogoutIcon: FunctionComponent<IconProps> = (props: PropsWithChildren<IconProps>) => {
    return (
        <CustomIcon className={props.className}>
            <Logout />
        </CustomIcon>
    );
};
