import React, { FunctionComponent } from 'react';

import styles from './IconAccept.module.scss';
import { IconAcceptProps } from './IconAccept.types';

const IconAccept: FunctionComponent<IconAcceptProps> = ({ className }: IconAcceptProps) => {
    return (
        <span className={`${styles.container} ${className ? className : ''}`}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#0F7A5A" />
                <path
                    d="M16.875 7L10.35 14.5L7.125 10.75L6 12L9.3 15.75L9.375 15.8333L10.35 17L18 8.25L16.875 7Z"
                    fill="white"
                />
            </svg>
        </span>
    );
};

export default IconAccept;
