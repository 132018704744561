import { createSlice } from '@reduxjs/toolkit';

import { fetchDiscount, revertAll } from './AppStepper.thunk';
import { DiscountState } from './AppStepper.types';

const discountInitialState: DiscountState = {
    discount: null,
    loading: 'idle',
};

const discountSlice = createSlice({
    name: 'discount',
    initialState: discountInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchDiscount.pending, (state) => {
            state.loading = 'pending';
        });
        builder.addCase(fetchDiscount.fulfilled, (state, action) => {
            state.loading = 'succeeded';
            if (action.payload) {
                state.discount = action.payload;
            }
        });
        builder.addCase(fetchDiscount.rejected, (state) => {
            state.loading = 'failed';
        });
        builder.addCase(revertAll, () => discountInitialState);
    },
});

const discountReducer = discountSlice.reducer;

export { discountReducer };
