import React, { FunctionComponent } from 'react';
import { ContentText } from '@fortum/elemental-ui';
import { FormattedNumber } from 'react-intl';
import Notification from 'src/components/Notification/Notification';
import IconAccept from 'src/components/IconAccept/IconAccept';
import IconReject from 'src/components/IconReject/IconReject';

import styles from './TotalDiscount.module.scss';
import { TotalDiscountProps } from './TotalDiscount.types';

const TotalDiscount: FunctionComponent<TotalDiscountProps> = ({ amount, noData }: TotalDiscountProps) => {
    return (
        <div className={`${styles.mainContainer} ${amount ? styles.accepted : styles.rejected}`}>
            <div className={styles.rowContainer}>
                <div className={styles.col}>
                    <ContentText size="xl" lineHeight={1.2}>
                        Suma bonifikaty
                    </ContentText>
                </div>
                <div className={styles.col}>
                    <div className={styles.status}>
                        {amount ? <IconAccept className={styles.icon} /> : <IconReject className={styles.icon} />}
                        <ContentText size="m">{`Bonifikata ${amount ? 'naliczona' : 'odrzucona'}`}</ContentText>
                    </div>
                    <ContentText className={styles.amount} size="xl" lineHeight={1.2}>
                        <FormattedNumber value={amount} style="currency" currency="PLN" />
                    </ContentText>
                </div>
            </div>
            {noData && (
                <Notification
                    hideCloseButton={true}
                    notificationType="warning"
                    notificationText="W wyliczonej bonifikacie nie zostały uwzględnione dni z brakującymi danymi w systemie"
                />
            )}
        </div>
    );
};

export default TotalDiscount;
