import React, { FunctionComponent, useEffect, useState } from 'react';
import { styles as elStyles, useHighlightActive, useTheme, colors, px2rem } from '@fortum/elemental-ui';

import { ListItemsProps } from './ListItems.types';
import { ListItem } from './ListItem';
import styles from './ListItems.module.scss';

export const ListItems: FunctionComponent<ListItemsProps> = ({
    disableActive,
    disableFocusTransfer,
    id = '',
    items,
    opened = false,
    selectWithSpacebar,
    showItems = 5,
    onActiveChange,
    onSelect,
    onClose,
    selected,
}: ListItemsProps) => {
    const theme = useTheme();
    const itemHeight = theme.listItems.web.height;
    const maxItems = items.length <= showItems ? items.length : showItems;
    const maxHeight = opened ? maxItems * itemHeight + 2 : 0;
    const duration = maxItems * 10 + 150;
    const hidden = !opened || items.length <= 0;
    const isOpened = items.length === 0 ? false : opened;
    const { active, handleMouseMove, activeRef } = useHighlightActive(opened, items, selected, onSelect, onClose, {
        disableActive,
        disableFocusTransfer,
        onActiveChange,
        selectWithSpacebar,
    });
    const [openAndTransitioned, setOpenAndTransiotioned] = useState<boolean>(false);

    useEffect(() => {
        if (!opened) {
            setOpenAndTransiotioned(false);
        }
    }, [opened]);

    return (
        <div style={{ position: 'absolute', width: '100%' }}>
            <ul
                id={id}
                className={styles.list}
                data-test="elemental-ListItems"
                role="listbox"
                aria-hidden={!opened}
                tabIndex={-1}
                onTransitionEnd={(e) => {
                    if (e.propertyName === 'max-height' && opened) {
                        setOpenAndTransiotioned(true);
                    }
                }}
                style={{
                    background: colors.snowWhite,
                    borderWidth: px2rem(2),
                    borderStyle: 'solid',
                    borderColor: isOpened ? colors.forestGreen : colors.fogGrey,
                    borderTopWidth: 0,
                    borderBottomWidth: 0,
                    top: 0,
                    transform: `translateY(${px2rem(-2)})`,
                    overflow: openAndTransitioned ? 'auto' : 'hidden',
                    position: 'relative',
                    maxHeight: maxHeight,
                    transitionTimingFunction: elStyles.transition.function.base,
                    transitionDuration: `${duration}ms`,
                    zIndex: isOpened ? 100 : 3,
                }}
            >
                {items.map((item, index) => {
                    const delay = opened ? (duration / maxItems) * (index < showItems ? index : showItems) : 0;
                    const isActive = active === item.value;
                    return (
                        <ListItem
                            active={isActive}
                            activeRef={isActive && opened ? activeRef : undefined}
                            ariaPosinset={index + 1}
                            ariaSetsize={items.length}
                            delay={delay}
                            id={
                                isActive
                                    ? ''.concat(id, '-').concat(item.value, '-active')
                                    : ''.concat(id, '-').concat(item.value)
                            }
                            item={item}
                            key={index}
                            onSelect={onSelect}
                            onMouseMove={handleMouseMove}
                            tabIndex={isActive && opened && !disableFocusTransfer ? 0 : -1}
                        />
                    );
                })}
            </ul>
            <div
                className={styles.appender}
                style={{
                    background: colors.snowWhite,
                    borderWidth: px2rem(2),
                    borderStyle: 'solid',
                    borderColor: colors.forestGreen,
                    borderTop: 0,
                }}
                hidden={hidden}
            />
        </div>
    );
};
