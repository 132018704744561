import { ContentText, Heading, IconError, Link, Loader, spacingNumerical } from '@fortum/elemental-ui';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ConsumptionPointDatesInput from 'src/containers/ConsumptionPointDatesInput/ConsumptionPointDatesInput';
import { DateRange } from 'src/containers/ConsumptionPointDatesInput/ConsumptionPointDatesInput.types';
import { AutoComplete } from 'src/components/AutoComplete/AutoComplete';
import { AppDispatch, RootState } from 'src/providers/store';

import { fetchConsumptionPointList } from './SecondStep.thunk';
import { ConsumptionPointItem } from './SecondStep.types';
import styles from './SecondStep.module.scss';

type SecondStepProps = {
    clientNumber: string;
    selectedItem: ConsumptionPointItem | null;
    setSelectedItem: (value: ConsumptionPointItem | null) => void;
    dateRangeArr: DateRange[];
    setDateRangeArr: React.Dispatch<React.SetStateAction<{ startDate: Date | null; endDate: Date | null }[]>>;
    skipAgreementRule: boolean;
    toggleAgreementRule: () => void;
};

const SecondStep: FunctionComponent<SecondStepProps> = ({
    clientNumber,
    selectedItem,
    setSelectedItem,
    dateRangeArr,
    setDateRangeArr,
    skipAgreementRule,
    toggleAgreementRule,
}: SecondStepProps) => {
    const consumptionPointState = useSelector((state: RootState) => state.consumptionPoints);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(fetchConsumptionPointList(clientNumber));
    }, []);

    const items: ConsumptionPointItem[] = consumptionPointState.consumptionPointList.map((item) => {
        return {
            value: item.consumptionPoint.id,
            name: item.consumptionPoint.address,
            substationName: item.substation.address,
            substationValue: item.substation.id,
        };
    });

    return (
        <>
            <Heading
                style={{
                    marginTop: spacingNumerical.m,
                    paddingTop: spacingNumerical.xxxs,
                    marginBottom: spacingNumerical.xs,
                }}
                lineHeight={1.2}
                level={2}
                styledAs={5}
                textAlign="left"
            >
                Punkty odbioru
            </Heading>
            {consumptionPointState.loading === 'pending' && (
                <div className={styles.centerContainer}>
                    <Loader />
                </div>
            )}
            {consumptionPointState.loading === 'succeeded' && consumptionPointState.consumptionPointList.length && (
                <>
                    <AutoComplete
                        name="consumptionPointAddress"
                        label="Adres punktu odbioru"
                        placeholder="Wyszukaj"
                        className={styles.inputField}
                        items={items}
                        valueProp={selectedItem ? selectedItem.name : ''}
                        valueHiddenProp={selectedItem ? selectedItem.value : ''}
                        onSelectItem={(value) => {
                            const item = items.find((item) => item.value === value);
                            item && setSelectedItem(item);
                        }}
                        onBlur={(e) => {
                            e.target.value === '' && setSelectedItem(null);
                        }}
                    />
                    {selectedItem && (
                        <ConsumptionPointDatesInput
                            index="1"
                            pointId={selectedItem.value}
                            pointAddress={selectedItem.name}
                            substationAddress={selectedItem.substationName}
                            substationId={selectedItem.substationValue}
                            dateRangeArr={dateRangeArr}
                            setDateRangeArr={setDateRangeArr}
                            onRemove={() => setSelectedItem(null)}
                            skipAgreementRule={skipAgreementRule}
                            toggleAgreementRule={toggleAgreementRule}
                        />
                    )}
                </>
            )}
            {consumptionPointState.loading === 'failed' && (
                <div className={styles.centerContainer}>
                    <IconError color={'#BF4F2A'} size={40} />
                    <ContentText color={'#41414A'}>Błąd wyświetlania</ContentText>
                    <ContentText color={'#777'} size={16}>
                        Nie udało się wczytać wyników &nbsp;
                        <Link size="s" onClick={() => dispatch(fetchConsumptionPointList(clientNumber))}>
                            Spróbuj ponownie
                        </Link>
                    </ContentText>
                </div>
            )}
        </>
    );
};

export default SecondStep;
