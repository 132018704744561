import { createAsyncThunk } from '@reduxjs/toolkit';
import { DiscountsMonthDetails } from 'src/api/model/DiscountsMonthDetails';
import { getDiscountsMonthDetails } from 'src/api/requests';

import { DiscountsMonthDetailsParams } from './CalculationDetailsModal.types';

const fetchDiscountsMonthDetails = createAsyncThunk(
    'discountsMonthDetails/get',
    async (param: DiscountsMonthDetailsParams): Promise<DiscountsMonthDetails | undefined> => {
        const { substationId, consumptionPointId, yearMonth } = param;
        return await getDiscountsMonthDetails(substationId, consumptionPointId, yearMonth);
    },
);

export { fetchDiscountsMonthDetails };
