import React, { FunctionComponent } from 'react';

import styles from './IconReject.module.scss';
import { IconRejectProps } from './IconReject.types';

const IconReject: FunctionComponent<IconRejectProps> = ({ className }: IconRejectProps) => {
    return (
        <span className={`${styles.container} ${className ? className : ''}`}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#BF4F2A" />
                <path
                    d="M17 8.04478L15.9552 7L12 10.9552L8.04478 7L7 8.04478L10.9552 12L7 15.9552L8.04478 17L12 13.0448L15.9552 17L17 15.9552L13.0448 12L17 8.04478Z"
                    fill="white"
                />
            </svg>
        </span>
    );
};

export default IconReject;
