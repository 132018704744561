import { ContentText, Heading, Modal, spacingNumerical } from '@fortum/elemental-ui';
import React, { FunctionComponent } from 'react';
import { FormattedNumber } from 'react-intl';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import { SelectedDiscountData } from 'src/containers/CalculationDetailsModal/CalculationDetailsModal.types';

import styles from './EachDayPaymentsModal.module.scss';

type EachDayPaymentsModalProps = {
    selectedDiscounts: SelectedDiscountData[];
    consumptionPoint: string;
    opened: boolean;
    onClose: () => void;
};

const EachDayPaymentsModal: FunctionComponent<EachDayPaymentsModalProps> = ({
    selectedDiscounts,
    consumptionPoint,
    opened,
    onClose,
}: EachDayPaymentsModalProps) => {
    return (
        <Modal className={styles.modal} opened={opened} onClose={onClose}>
            <Heading
                style={{
                    marginBottom: spacingNumerical.xxxs,
                }}
                lineHeight={1.2}
                level={3}
                styledAs={5}
                textAlign="center"
            >
                Należność za poszczególne dni
            </Heading>
            <ContentText size="xl" lineHeight={1.2} textAlign="center">
                {consumptionPoint}
            </ContentText>
            <div className={styles.tableHeaderContainer}>
                <ContentText size="s">Dni uznane</ContentText>
                <ContentText size="s">Należność za dzień</ContentText>
            </div>
            {selectedDiscounts
                .sort(
                    (a, b) =>
                        parse(a.date, 'yyyy-MM-dd', new Date()).getTime() -
                        parse(b.date, 'yyyy-MM-dd', new Date()).getTime(),
                )
                .map((item, index) => (
                    <div key={item.date} className={`${styles.tableRowContainer} ${!(index % 2) ? styles.odd : ''}`}>
                        <ContentText lineHeight={1.2}>
                            {format(parse(item.date, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy')}
                        </ContentText>
                        <ContentText lineHeight={1.2}>
                            <FormattedNumber value={item.amount} style="currency" currency="PLN" />
                        </ContentText>
                    </div>
                ))}
        </Modal>
    );
};

export default EachDayPaymentsModal;
