import React, { FunctionComponent } from 'react';
import { ContentText, IconButton, IconCross, IconInfo, Loader, Link } from '@fortum/elemental-ui';
import IconError from 'src/components/IconError/IconError';
import IconSuccess from 'src/components/IconSuccess/IconSuccess';
import { assertUnreachable } from 'src/utils/assertUnreachable';

import { NotificationProps } from './Notification.types';
import styles from './Notification.module.scss';

const Notification: FunctionComponent<NotificationProps> = ({
    backgroundColor = '#ffffff',
    notificationType,
    notificationText,
    notificationRetryLink,
    notificationLinkText,
    hideCloseButton = false,
    setClosed,
}: NotificationProps) => {
    const renderIcon = () => {
        switch (notificationType) {
            case 'success':
                return <IconSuccess className={styles.icon} />;
            case 'warning':
                return <IconError className={styles.icon} fill={'#333333'} />;
            case 'info':
                return <IconInfo className={styles.icon} />;
            case 'loading':
                return <Loader size={24} color={'#406AAD'} className={styles.icon} />;
            default:
                assertUnreachable(notificationType);
        }
    };

    return (
        <div style={{ backgroundColor }} className={`${styles.notificationContainer}`}>
            <div className={`${styles.leftLine} ${styles[notificationType]}`} />
            <div style={{ backgroundColor }} className={styles.mainContent}>
                <div className={styles.notificationText}>
                    {renderIcon()}
                    <ContentText>{notificationText}&nbsp;</ContentText>
                    {notificationRetryLink && <Link onClick={notificationRetryLink}>{`Spróbuj ponownie`}</Link>}
                </div>
                {notificationLinkText && (
                    <div className={styles.notificationLink}>
                        <Link>{notificationLinkText}</Link>
                    </div>
                )}
                {!hideCloseButton && !!setClosed && (
                    <IconButton
                        icon={IconCross}
                        color={backgroundColor}
                        textColor="#333333"
                        onClick={() => setClosed()}
                    />
                )}
            </div>
        </div>
    );
};

export default Notification;
