import React, { FunctionComponent, useEffect, useState } from 'react';
import { colors, ContentText, IconInfo, Link, Popover, spacing, usePopover } from '@fortum/elemental-ui';
import { FormattedNumber } from 'react-intl';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import isEqual from 'date-fns/isEqual';
import addDays from 'date-fns/addDays';

// import Notification from 'src/components/Notification/Notification';
import EachDayPaymentsModal from 'src/containers/EachDayPaymentsModal/EachDayPaymentsModal';
import CalculationDetailsModal from 'src/containers/CalculationDetailsModal/CalculationDetailsModal';
import IconAccept from 'src/components/IconAccept/IconAccept';
import IconReject from 'src/components/IconReject/IconReject';
import IconError from 'src/components/IconError/IconError';
import { Daily, RestrictionStatus } from 'src/api/model/Discount';
import { SelectedDiscountData } from 'src/containers/CalculationDetailsModal/CalculationDetailsModal.types';

import { ConsumptionPointSummaryProps, PopoverVariant } from './ConsumptionPointSummary.types';
import styles from './ConsumptionPointSummary.module.scss';

const ConsumptionPointSummary: FunctionComponent<ConsumptionPointSummaryProps> = ({
    clientName,
    discount,
    dateRangeArr,
    consumptionPoint,
    selectedDiscounts,
    setSelectedDiscounts,
    skipAgreementRule,
}: ConsumptionPointSummaryProps) => {
    const [eachDayModalOpen, setEachDayModalOpen] = useState<boolean>(false);
    const [calculationDetailsModalOpen, setCalculationDetailsModalOpen] = useState<boolean>(false);
    const [popoverVariant, setPopoverVariant] = useState<PopoverVariant | null>(null);
    const { open, anchor, handleOpen, handleClose } = usePopover();

    const handleOpenPopover = (e: React.SyntheticEvent<HTMLElement, Event>, variant: PopoverVariant) => {
        setPopoverVariant(variant);
        handleOpen(e);
    };

    const handleEachDayModalClose = () => {
        setEachDayModalOpen(false);
    };

    const handleCalculationDetailsModalClose = () => {
        setCalculationDetailsModalOpen(false);
    };

    const getPopoverText = () => {
        switch (popoverVariant) {
            case PopoverVariant.INSUFFICIENT_DATA:
                return <>Brak danych o ograniczeniach w dostawie ciepła</>;
            case PopoverVariant.NOT_GRANTED:
                return (
                    <>
                        Dzień nie spełnia warunku umownego dotyczącego
                        <br />
                        ilości i długości ograniczeń
                    </>
                );
            case PopoverVariant.USER_MODIFIED:
                return (
                    <>
                        Uwzględniony dzień spoza zakresu reklamacji
                        <br />
                        zgłoszonej przez klienta
                    </>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        const dailyGranted = discount.daily.filter(
            (item) =>
                item.status === 'GRANTED' &&
                (skipAgreementRule ||
                    discount.monthlyRestrictionStatus[format(parse(item.date, 'yyyy-MM-dd', new Date()), 'yyyy-MM')] ===
                        RestrictionStatus.NOT_RESTRICTED),
        );
        const selected: SelectedDiscountData[] = dailyGranted.map((item) => ({
            date: item.date,
            amount: item.value,
            checked: true,
        }));
        setSelectedDiscounts(selected);
    }, [discount]);

    const grantedByStatus = discount.daily.filter((item) => item.status === 'GRANTED');

    const granted = discount.daily.filter(
        (item) =>
            item.status === 'GRANTED' &&
            (skipAgreementRule ||
                discount.monthlyRestrictionStatus[format(parse(item.date, 'yyyy-MM-dd', new Date()), 'yyyy-MM')] ===
                    RestrictionStatus.NOT_RESTRICTED),
    );
    const notGranted = discount.daily.filter(
        (item) =>
            item.status === 'NOT_GRANTED' ||
            (item.status === 'GRANTED' &&
                !skipAgreementRule &&
                discount.monthlyRestrictionStatus[format(parse(item.date, 'yyyy-MM-dd', new Date()), 'yyyy-MM')] ===
                    RestrictionStatus.RESTRICTED),
    );
    const insufficientData = discount.daily.filter(
        (item) =>
            item.status === 'INSUFFICIENT_DATA' ||
            (item.status === 'GRANTED' &&
                !skipAgreementRule &&
                discount.monthlyRestrictionStatus[format(parse(item.date, 'yyyy-MM-dd', new Date()), 'yyyy-MM')] ===
                    RestrictionStatus.UNKNOWN),
    );
    const amountGranted = selectedDiscounts.reduce((accumulator, item) => {
        return accumulator + Math.round(item.amount * 100) / 100;
    }, 0);

    const inArray = (daily: Daily[], date: Date) =>
        daily.findIndex((d) => isEqual(parse(d.date, 'yyyy-MM-dd', new Date()), date)) !== -1;

    const showDatesAsDateRange = (
        daily: Daily[] | SelectedDiscountData[],
        prefix: string,
        checkedDataArray: Daily[],
        popoverVariant: PopoverVariant | null,
        isInArrayCheck: boolean,
    ) => {
        const dateArr = daily.map((item) => parse(item.date, 'yyyy-MM-dd', new Date()));
        const newArr = [...dateArr].sort((a, b) => a.getTime() - b.getTime());
        let startPoint: Date | null = null;
        let prevDate: Date | null = null;

        const getValue = (value: boolean, isNegated: boolean) => (isNegated ? !value : value);

        return newArr.map((date, index, array) => {
            const lastDate = !(array.length > index + 1);
            let result: string | null = null;
            let resultLast: string | null = null;
            let showIcon: boolean | null = null;
            let showIconLast: boolean | null = null;
            if (startPoint && prevDate) {
                if (
                    isEqual(addDays(prevDate, 1), date) &&
                    ((inArray(checkedDataArray, date) && inArray(checkedDataArray, prevDate)) ||
                        (!inArray(checkedDataArray, date) && !inArray(checkedDataArray, prevDate)))
                ) {
                    prevDate = date;
                    if (lastDate) {
                        if (isEqual(startPoint, prevDate)) {
                            result = `${format(startPoint, 'dd.MM.yyyy')}`;
                            showIcon = getValue(inArray(checkedDataArray, startPoint), !isInArrayCheck);
                        } else {
                            result = `${format(startPoint, 'dd.MM.yyyy')} - ${format(prevDate, 'dd.MM.yyyy')}`;
                            showIcon = getValue(inArray(checkedDataArray, prevDate), !isInArrayCheck);
                        }
                    }
                } else {
                    if (isEqual(startPoint, prevDate)) {
                        result = `${format(startPoint, 'dd.MM.yyyy')}`;
                        showIcon = getValue(inArray(checkedDataArray, startPoint), !isInArrayCheck);
                    } else {
                        result = `${format(startPoint, 'dd.MM.yyyy')} - ${format(prevDate, 'dd.MM.yyyy')}`;
                        showIcon = getValue(inArray(checkedDataArray, prevDate), !isInArrayCheck);
                    }

                    if (lastDate) {
                        resultLast = `${format(date, 'dd.MM.yyyy')}`;
                        showIconLast = getValue(inArray(checkedDataArray, date), !isInArrayCheck);
                    }

                    prevDate = date;
                    startPoint = date;
                }
            } else {
                if (lastDate) {
                    result = `${format(date, 'dd.MM.yyyy')}`;
                    showIcon = getValue(inArray(checkedDataArray, date), !isInArrayCheck);
                }
                startPoint = date;
                prevDate = date;
            }

            if (result !== null && showIcon !== null) {
                return (
                    <React.Fragment key={`${prefix}-${result.replace(' ', '')}`}>
                        <>
                            {renderDate(result, showIcon, popoverVariant)}
                            {array.length > index + 1 && <br />}
                        </>
                        {resultLast && showIconLast !== null && (
                            <>
                                {array.length === index + 1 && <br />}
                                {renderDate(resultLast, showIconLast, popoverVariant)}
                            </>
                        )}
                    </React.Fragment>
                );
            }
        });
    };

    const renderDate = (date: string, showIcon: boolean, popoverVariant: PopoverVariant | null) => (
        <>
            {showIcon ? (
                <span className={styles.date}>
                    <span className={styles.dateItem}>{date}</span>
                    {popoverVariant && (
                        <IconInfo
                            size={16}
                            color="#406AAD"
                            onMouseEnter={(e) => handleOpenPopover(e, popoverVariant)}
                            onFocus={(e) => handleOpenPopover(e, popoverVariant)}
                            onBlur={handleClose}
                            onMouseLeave={handleClose}
                        />
                    )}
                </span>
            ) : (
                date
            )}
        </>
    );

    return (
        <div className={styles.mainContainer}>
            <div className={styles.topContainer}>
                <div className={styles.pointAddress}>
                    <ContentText size="s" lineHeight={1.2}>
                        Adres punktu odbioru
                    </ContentText>
                    <ContentText size="xl" lineHeight={1.2}>
                        {consumptionPoint.name}
                    </ContentText>
                </div>
                <div className={styles.substationAddress}>
                    <div>
                        <ContentText size="s" lineHeight={1.2}>
                            Adres i kod węzła
                        </ContentText>
                        <ContentText size="xl" lineHeight={1.2}>
                            {consumptionPoint.substationName} ({consumptionPoint.substationValue})
                        </ContentText>
                    </div>
                </div>
            </div>
            <div className={styles.bottomContainer}>
                <div className={styles.systemCol}>
                    <ContentText size="s" lineHeight={1.2}>
                        Dni zgłoszone przez klienta i zaklasyfikowane przez system
                    </ContentText>
                    <div className={styles.systemContainer}>
                        <div className={styles.col}>
                            <ContentText size="s" lineHeight={1.2}>
                                Dni uznane
                            </ContentText>
                            <div className={styles.details}>
                                <ContentText size="m">
                                    {granted.length ? showDatesAsDateRange(granted, 'accepted', [], null, true) : '-'}
                                </ContentText>
                            </div>
                        </div>
                        <div className={styles.col}>
                            <ContentText size="s" lineHeight={1.2}>
                                Dni nieuznane
                            </ContentText>
                            <div className={styles.details}>
                                <ContentText size="m">
                                    {notGranted.length
                                        ? showDatesAsDateRange(
                                              notGranted,
                                              'notAccepted',
                                              grantedByStatus,
                                              PopoverVariant.NOT_GRANTED,
                                              true,
                                          )
                                        : '-'}
                                </ContentText>
                            </div>
                        </div>
                        <div className={styles.col}>
                            <ContentText size="s" lineHeight={1.2} marginLeft={40}>
                                Brak danych z dni
                            </ContentText>
                            <div className={styles.details}>
                                <ContentText size="m">
                                    {insufficientData.length
                                        ? showDatesAsDateRange(
                                              insufficientData,
                                              'noData',
                                              grantedByStatus,
                                              PopoverVariant.INSUFFICIENT_DATA,
                                              true,
                                          )
                                        : '-'}
                                </ContentText>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.userCol}>
                    <ContentText size="s" lineHeight={1.2}>
                        Zatwierdzone przez pracownika
                    </ContentText>
                    <ContentText size="s" lineHeight={1.2}>
                        Dni uznane
                    </ContentText>
                    <div className={styles.details}>
                        <ContentText size="m">
                            {selectedDiscounts.length
                                ? showDatesAsDateRange(
                                      selectedDiscounts,
                                      'userSelected',
                                      granted,
                                      PopoverVariant.USER_MODIFIED,
                                      false,
                                  )
                                : '-'}
                        </ContentText>
                        {!!selectedDiscounts.length && (
                            <div className={styles.paymentDetailsLink}>
                                <Link onClick={() => setEachDayModalOpen(true)}>
                                    Pokaż należność za poszczególne dni
                                </Link>
                            </div>
                        )}
                        <Popover opened={open} anchor={anchor} anchorPos="bottom">
                            <ContentText size={14} p={spacing.xs} color={colors.snowWhite}>
                                {getPopoverText()}
                            </ContentText>
                        </Popover>
                    </div>
                </div>
                <div className={styles.summaryCol}>
                    <ContentText size="s" lineHeight={1.2}>
                        &nbsp;
                    </ContentText>
                    <ContentText size="s" lineHeight={1.2}>
                        Wynik kalkulacji
                    </ContentText>
                    <div className={styles.details}>
                        <div className={styles.status}>
                            {amountGranted ? (
                                <IconAccept className={styles.icon} />
                            ) : (
                                <IconReject className={styles.icon} />
                            )}
                            <ContentText size="m">
                                {amountGranted ? 'Bonifikata naliczona' : 'Bonifikata odrzucona'}
                            </ContentText>
                        </div>
                        {!!insufficientData.length && (
                            <div className={styles.status}>
                                <IconError className={styles.icon} />
                                <ContentText size="m">Brak danych w systemie</ContentText>
                            </div>
                        )}
                        {!!amountGranted && (
                            <ContentText className={styles.amount} size="xl" lineHeight={1.2}>
                                <FormattedNumber value={amountGranted} style="currency" currency="PLN" />
                            </ContentText>
                        )}
                        <Link className={styles.detailsLink} onClick={() => setCalculationDetailsModalOpen(true)}>
                            Pokaż szczegóły kalkulacji
                        </Link>
                    </div>
                </div>
            </div>
            {/*<Notification />*/}
            <EachDayPaymentsModal
                selectedDiscounts={selectedDiscounts}
                consumptionPoint={consumptionPoint.name}
                opened={eachDayModalOpen}
                onClose={handleEachDayModalClose}
            />
            <CalculationDetailsModal
                clientName={clientName}
                opened={calculationDetailsModalOpen}
                onClose={handleCalculationDetailsModalClose}
                dateRangeArr={dateRangeArr}
                consumptionPoint={consumptionPoint}
                discount={discount}
                selectedDiscounts={selectedDiscounts}
                setSelectedDiscounts={setSelectedDiscounts}
                skipAgreementRule={skipAgreementRule}
            />
        </div>
    );
};

export default ConsumptionPointSummary;
