import { getUrlParam } from './getUrlParam';

/* eslint-disable @typescript-eslint/no-explicit-any */

export type Logger = {
    showLogs: boolean;
    log: (message?: any, ...optionalParams: any[]) => void;
    info: (message?: any, ...optionalParams: any[]) => void;
    success: (message?: any, ...optionalParams: any[]) => void;
    warning: (message?: any, ...optionalParams: any[]) => void;
    error: (message?: any, ...optionalParams: any[]) => void;
    group: (...label: any[]) => void;
    groupCollapsed: (...label: any[]) => void;
    groupEnd: () => void;
    time: (label?: string) => void;
    timeEnd: (label?: string) => void;
    timeLog: (label?: string, ...data: any[]) => void;
};

export const createLogger = (): Logger => {
    const showLogs: boolean =
        process.env.NODE_ENV === 'development' || getUrlParam(location.search, 'showLogs') === 'true';

    /**
     * Prints to `stdout` with newline.
     */
    const log = (message?: any, ...optionalParams: any[]): void => {
        if (showLogs) {
            if (optionalParams.length) {
                console.log(`[LOG] ${message}`, optionalParams);
            } else {
                console.log(`[LOG] ${message}`);
            }
        }
    };

    /**
     * The {@link console.info()} function is an alias for {@link console.log()}.
     */
    const info = (message?: any, ...optionalParams: any[]): void => {
        if (showLogs) {
            if (optionalParams.length) {
                console.info(`[INFO] ${message}`, optionalParams);
            } else {
                console.info(`[INFO] ${message}`);
            }
        }
    };

    const success = (message?: any, ...optionalParams: any[]): void => {
        if (showLogs) {
            if (optionalParams.length) {
                console.log(`[SUCCESS] ${message}`, optionalParams);
            } else {
                console.log(`[SUCCESS] ${message}`);
            }
        }
    };

    /**
     * The {@link console.warn()} function is an alias for {@link console.error()}.
     */
    const warning = (message?: any, ...optionalParams: any[]): void => {
        console.log('showLogs: ' + showLogs);
        console.log('proces.env: ' + process.env.NODE_ENV);
        if (showLogs) {
            if (optionalParams.length) {
                console.warn(`[WARNING] ${message}`, optionalParams);
            } else {
                console.warn(`[WARNING] ${message}`);
            }
        }
    };

    /**
     * Prints to `stderr` with newline.
     */
    const error = (message?: any, ...optionalParams: any[]): void => {
        if (showLogs) {
            if (optionalParams.length) {
                console.error(`[ERROR] ${message}`, optionalParams);
            } else {
                console.error(`[ERROR] ${message}`);
            }
        }
    };

    /**
     * Increases indentation of subsequent lines by two spaces.
     * If one or more `label`s are provided, those are printed first without the additional indentation.
     */
    const group = (...label: any[]): void => {
        if (showLogs) {
            console.group(`[GROUP] ${label}`);
        }
    };

    /**
     * The `console.groupCollapsed()` function is an alias for {@link console.group()}.
     */
    const groupCollapsed = (...label: any[]): void => {
        if (showLogs) {
            console.groupCollapsed(`[GROUP COLLAPSED] ${label}`);
        }
    };

    /**
     * Decreases indentation of subsequent lines by two spaces.
     */
    const groupEnd = (): void => {
        if (showLogs) {
            console.groupEnd();
        }
    };

    /**
     * Starts a timer that can be used to compute the duration of an operation. Timers are identified by a unique `label`.
     */
    const time = (label?: string): void => {
        if (showLogs) {
            label = label ? label : '';
            console.time(`[TIME] ${label}`);
        }
    };

    /**
     * Stops a timer that was previously started by calling {@link console.time()} and prints the result to `stdout`.
     */
    const timeEnd = (label?: string): void => {
        if (showLogs) {
            label = label ? label : '';
            console.timeEnd(`[TIME] ${label}`);
        }
    };

    /**
     * For a timer that was previously started by calling {@link console.time()}, prints the elapsed time and other `data` arguments to `stdout`.
     */
    const timeLog = (label?: string, ...data: any[]): void => {
        if (showLogs) {
            label = label ? label : '';
            if (!data.length) {
                console.timeLog(`[TIME] ${label}`);
            } else {
                console.timeLog(`[TIME] ${label}`, data);
            }
        }
    };

    return {
        showLogs,
        log,
        info,
        success,
        warning,
        error,
        group,
        groupCollapsed,
        groupEnd,
        time,
        timeEnd,
        timeLog,
    };
};

export const logger = createLogger();
