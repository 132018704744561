import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState, setAuthToken, clearAuthToken } from 'src/providers/auth';
import { logedPath, logoutedPath } from 'src/auth';

import Home from './Home/Home';
import Login from './Login/Login';

const Pages: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector<{ auth: AuthState }>((state) => state.auth.token);

    useEffect(() => {
        if (window.location.pathname === logedPath) {
            const params = new URLSearchParams(window.location.hash.replace('#', ''));
            const accessToken = params.get('access_token');
            if (accessToken) {
                dispatch(setAuthToken(accessToken));
                navigate('/');
            }
        } else if (window.location.pathname === logoutedPath) {
            dispatch(clearAuthToken());
            navigate('/login');
        } else if (token === null) {
            navigate('/login');
        }
    }, []);

    return (
        <Routes>
            <Route element={<Home />} path="/" />
            <Route element={<Login />} path="/login" />
        </Routes>
    );
};

export default Pages;
