import React, { FunctionComponent, useMemo, useRef, useState, useCallback, useEffect } from 'react';
import {
    InputField,
    startsWith,
    useOutsideClickClose,
    callOptional,
    useSmartControlledEventHandler,
    useForkRef,
    IconChevronDown,
} from '@fortum/elemental-ui';

import { ListItems } from 'src/components/ListItems/ListItems';

import { AutoCompleteProps, AutoCompleteItem } from './AutoComplete.types';
import styles from './AutoComplete.module.scss';

const defaultFilterItems = (items: AutoCompleteItem[], filter: string): AutoCompleteItem[] => {
    return items.filter((item) => {
        return (
            item.name.toLowerCase().includes(filter.toLowerCase()) ||
            startsWith(item.value.toLowerCase(), filter.toLowerCase())
        );
    });
};

const filterItems = (
    items: AutoCompleteItem[],
    filter: string,
    customFilterItems?: (items: AutoCompleteItem[], filter: string) => AutoCompleteItem[],
    maxItems?: number,
): AutoCompleteItem[] => {
    if (!items) return [];
    const filtered = customFilterItems ? customFilterItems(items, filter) : defaultFilterItems(items, filter);
    const itemized = filtered.map((item) => item);
    return itemized.slice(0, maxItems || filtered.length);
};

const createSyntheticEvent = <T extends Element, E extends Event>(event: E): React.SyntheticEvent<T, E> => {
    let isDefaultPrevented = false;
    let isPropagationStopped = false;
    const preventDefault = () => {
        isDefaultPrevented = true;
        event.preventDefault();
    };
    const stopPropagation = () => {
        isPropagationStopped = true;
        event.stopPropagation();
    };
    return {
        nativeEvent: event,
        currentTarget: event.currentTarget as EventTarget & T,
        target: event.target as EventTarget & T,
        bubbles: event.bubbles,
        cancelable: event.cancelable,
        defaultPrevented: event.defaultPrevented,
        eventPhase: event.eventPhase,
        isTrusted: event.isTrusted,
        preventDefault,
        isDefaultPrevented: () => isDefaultPrevented,
        stopPropagation,
        isPropagationStopped: () => isPropagationStopped,
        persist: () => ({}),
        timeStamp: event.timeStamp,
        type: event.type,
    };
};

const changeEvent = (target: AutoCompleteItem) => {
    const event = new Event('change');
    Object.defineProperty(event, 'target', { writable: true, value: target });
    Object.defineProperty(event, 'currentTarget', { writable: true, value: target });
    return createSyntheticEvent(event) as React.ChangeEvent<HTMLInputElement>;
};

export const AutoComplete: FunctionComponent<AutoCompleteProps> = ({
    className,
    id,
    inputMode,
    inputRefProp,
    items,
    filterItemsProp,
    label,
    emptyLabel,
    maxItems,
    name,
    onBlur,
    onChangeProp,
    onFocus,
    onSelectItem,
    placeholder,
    valueProp,
    showItems = 4,
}: AutoCompleteProps) => {
    const [opened, setOpened] = useState<boolean>(false);
    const [disableActive, setDisableActive] = useState<boolean>(false);
    const [valueSelected, setValueSelected] = useState<boolean>(false);
    const [activeListElement, setActiveListElement] = useState<HTMLLIElement | undefined>(undefined);
    const internalInputRef = useRef<HTMLInputElement>(null);
    const inputRef = inputRefProp ? useForkRef(inputRefProp, internalInputRef) : internalInputRef;
    const defaultItem = valueProp ? items.find((i) => i.value === valueProp) : undefined;
    const [value, onChange] = useSmartControlledEventHandler<string, HTMLInputElement>(
        '',
        defaultItem ? defaultItem.name : '',
        onChangeProp,
    );
    const [valueHidden, onChangeHidden] = useSmartControlledEventHandler<string, HTMLInputElement>('', valueProp);
    const handleOpen = () => {
        setOpened(true);
    };
    const handleClose = () => {
        setOpened(false);
    };
    const _useOutsideClickClose = useOutsideClickClose(handleClose);
    const wrapperRef = _useOutsideClickClose.wrapperRef;
    const handleAutocompleteBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (e.relatedTarget && wrapperRef.current && wrapperRef.current.contains(e.relatedTarget)) {
                return;
            }

            const target: AutoCompleteItem = { name: name, value: '' };
            const hiddenTarget: AutoCompleteItem = { name: `${name}Hidden`, value: '' };

            if (!valueSelected) {
                onChange(changeEvent(target));
                onChangeHidden(changeEvent(hiddenTarget));
            }

            const crafted = {
                ...e,
                target: {
                    ...e.target,
                    value:
                        (valueSelected &&
                            internalInputRef &&
                            internalInputRef.current &&
                            internalInputRef.current.value) ||
                        '',
                },
            };

            setTimeout(function () {
                callOptional(onBlur)(crafted);
                handleClose();
            });
        },
        [handleClose, onBlur, onChange],
    );
    const handleAutocompleteFocus = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (e.relatedTarget && wrapperRef.current && wrapperRef.current.contains(e.relatedTarget)) {
                return;
            }
            callOptional(onFocus)(e);
            handleOpen();
        },
        [handleOpen, onFocus],
    );
    const handleInputKeyDown = useCallback(
        (e: React.KeyboardEvent) => {
            if (
                e.key !== 'ArrowRight' &&
                e.key !== 'ArrowLeft' &&
                e.key !== 'ArrowDown' &&
                e.key !== 'ArrowUp' &&
                e.key !== 'Enter' &&
                e.key !== 'Escape'
            ) {
                handleOpen();
                setDisableActive(true);
                setValueSelected(false);
            } else {
                setDisableActive(false);
            }
        },
        [handleOpen, setDisableActive, setValueSelected],
    );
    const handleListInputChange = useCallback(
        (value: string) => {
            if (internalInputRef && internalInputRef.current) {
                internalInputRef.current.focus();
            }

            setValueSelected(true);

            const item = items.find((i) => i.value === value);

            const target: AutoCompleteItem = { name: name, value: item?.name || '' };
            const hiddenTarget: AutoCompleteItem = { name: `${name}Hidden`, value: value };

            onChange(changeEvent(target));
            onChangeHidden(changeEvent(hiddenTarget));

            callOptional(onSelectItem)(value);
            handleClose();
        },
        [onChange, handleClose, inputRef, setValueSelected],
    );

    const handleCloseFromList = useCallback(() => {
        handleClose();
    }, [handleClose]);
    const filteredItems = useMemo(() => {
        return filterItems(items, value, filterItemsProp, maxItems);
    }, [items, value, filterItemsProp, maxItems]);

    useEffect(() => {
        if (valueProp === '') {
            const target: AutoCompleteItem = { name: name, value: '' };
            onChange(changeEvent(target));
        }
    }, [valueProp]);

    return (
        <div
            className={`${styles.autocompleteWrap} ${className ? className : ''}`}
            data-test={'elemental-AutoComplete-'.concat(id || name)}
            onBlurCapture={handleAutocompleteBlur}
            onFocusCapture={handleAutocompleteFocus}
            ref={wrapperRef}
        >
            <div className={styles.inputWrap}>
                <InputField
                    autoComplete="off"
                    inputRef={inputRef}
                    label={emptyLabel && !opened && value === '' ? emptyLabel : label}
                    name={name}
                    rightIcon={
                        <IconChevronDown
                            size={24}
                            color={'#333333'}
                            className={opened ? styles.inputIconOpen : styles.inputIcon}
                        />
                    }
                    onKeyDown={handleInputKeyDown}
                    onChange={onChange}
                    onClick={handleOpen}
                    placeholder={placeholder}
                    value={value}
                    type="text"
                    disableBottomBorderRadius={filteredItems.length > 0 && opened}
                    inputProps={{
                        'aria-expanded': opened && filteredItems.length > 0,
                        role: 'combobox',
                        'aria-controls': opened ? 'elemental-Autocomplete-results-'.concat(id || name) : undefined,
                        'aria-autocomplete': 'list',
                        'aria-activedescendant': opened
                            ? activeListElement === null || activeListElement === void 0
                                ? void 0
                                : activeListElement.id
                            : undefined,
                        inputMode: inputMode,
                    }}
                />
            </div>
            <ListItems
                disableActive={disableActive}
                disableFocusTransfer={true}
                id={'elemental-Autocomplete-results-'.concat(id || name)}
                items={filteredItems}
                onActiveChange={(el) => {
                    setActiveListElement(el);
                }}
                onClose={handleCloseFromList}
                onSelect={handleListInputChange}
                opened={opened}
                selectWithSpacebar={false}
                showItems={showItems}
            />
            <input type="hidden" name={`${name}Hidden`} value={valueHidden} />
        </div>
    );
};
