import { configureStore } from '@reduxjs/toolkit';
import { clientsListReducer } from 'src/containers/FirstStep/FirstStep.slice';
import { consumptionPointListReducer } from 'src/containers/SecondStep/SecondStep.slice';
import { dailyDataListReducer } from 'src/containers/ConsumptionPointDatesInput/ConsumptionPointDatesInput.slice';
import { discountReducer } from 'src/containers/AppStepper/AppStepper.slice';
import { discountsMonthDetailsReducer } from 'src/containers/CalculationDetailsModal/CalculationDetailsModal.slice';

import authReducer from './auth';

const reducer = {
    auth: authReducer,
    clientsList: clientsListReducer,
    consumptionPoints: consumptionPointListReducer,
    dailyDatas: dailyDataListReducer,
    discountState: discountReducer,
    discountsMonthDetailsState: discountsMonthDetailsReducer,
};

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
