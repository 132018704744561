import React, { FunctionComponent } from 'react';
import { Circle, Font, Page, Path, Text, View, Document, StyleSheet, Svg } from '@react-pdf/renderer';
import { ConsumptionPointItem } from 'src/containers/SecondStep/SecondStep.types';
import { Daily, Discount, RestrictionStatus } from 'src/api/model/Discount';
import { SelectedDiscountData } from 'src/containers/CalculationDetailsModal/CalculationDetailsModal.types';
import { groupBy } from 'src/utils/groupBy';
import parse from 'date-fns/parse';
import isEqual from 'date-fns/isEqual';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';

Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
});

const months = [
    'Styczeń',
    'Luty',
    'Marzec',
    'Kwiecień',
    'Maj',
    'Czerwiec',
    'Lipiec',
    'Sierpień',
    'Wrzesień',
    'Październik',
    'Listopad',
    'Grudzień',
];

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        padding: 16,
        width: '100vw',
        display: 'flex',
    },
    section: {
        marginBottom: 20,
    },
    h1: {
        fontSize: 14,
        color: '#333333',
    },
    text: {
        fontSize: 9,
        color: '#333333',
    },
    consumptionPointBox: {
        backgroundColor: '#F7F7F7',
        padding: 16,
        marginBottom: 8,
    },
    topContainer: {
        paddingBottom: 16,
        borderBottom: '0.5pt solid #c6c6c6',
        display: 'flex',
        flexDirection: 'row',
    },
    pointAddress: {
        flex: 1,
        paddingRight: 3,
    },
    substationAddress: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
    },
    text1: {
        fontSize: 8,
        color: '#4A4A4A',
    },
    text2: {
        fontSize: 11,
        color: '#4A4A4A',
    },
    text3: {
        fontSize: 6,
        color: '#555555',
        marginBottom: 10,
    },
    text4: {
        fontSize: 8,
        color: '#333333',
    },
    text5: {
        fontSize: 6,
        color: '#555555',
        marginBottom: 7,
    },
    text6: {
        fontSize: 6,
        color: '#555555',
        marginBottom: 7,
        marginLeft: 15,
    },
    bottomContainer: {
        paddingTop: 12,
        display: 'flex',
        flexDirection: 'row',
    },
    systemCol: {
        width: 310,
        paddingRight: 6,
        borderRight: '0.5pt solid #c6c6c6',
    },
    userCol: {
        width: 104,
        paddingHorizontal: 8,
        borderRight: '0.5pt solid #c6c6c6',
    },
    summaryCol: {
        paddingLeft: 16,
        width: 117,
    },
    systemContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    col: {
        flex: 1,
        paddingRight: 10,
    },
    colLast: {
        flex: 1,
        paddingRight: 0,
    },
    test: {
        width: 563,
        height: 20,
        backgroundColor: '#f00',
    },
    status: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
    },
    icon: {
        marginRight: 6,
    },
    amount: {
        marginLeft: 15,
        marginBottom: 6,
        fontSize: 12,
    },
    monthAmount: {
        marginLeft: 15,
        marginBottom: 6,
        fontSize: 9,
    },
    totalBox: {
        padding: 16,
    },
    accepted: {
        backgroundColor: '#E7F2EF',
    },
    rejected: {
        backgroundColor: '#F9EDEA',
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    colTotal: {
        width: 101,
    },
    summaryText: {
        fontSize: 10,
        color: '#333333',
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    date: {
        fontSize: 7,
        color: '#333333',
        marginRight: 3,
    },
});

type PdfProps = {
    clientName: string;
    clientAddress: string;
    complaintNumber: string;
    consumptionPoint: ConsumptionPointItem;
    discount: Discount;
    selectedDiscounts: SelectedDiscountData[];
    skipAgreementRule: boolean;
};

const Pdf: FunctionComponent<PdfProps> = ({
    clientName,
    clientAddress,
    complaintNumber,
    consumptionPoint,
    discount,
    selectedDiscounts,
    skipAgreementRule,
}) => {
    const grantedByStatus = discount.daily.filter((item) => item.status === 'GRANTED');

    const granted = discount.daily.filter(
        (item) =>
            item.status === 'GRANTED' &&
            (skipAgreementRule ||
                discount.monthlyRestrictionStatus[format(parse(item.date, 'yyyy-MM-dd', new Date()), 'yyyy-MM')] ===
                    RestrictionStatus.NOT_RESTRICTED),
    );
    const notGranted = discount.daily.filter(
        (item) =>
            item.status === 'NOT_GRANTED' ||
            (item.status === 'GRANTED' &&
                !skipAgreementRule &&
                discount.monthlyRestrictionStatus[format(parse(item.date, 'yyyy-MM-dd', new Date()), 'yyyy-MM')] ===
                    RestrictionStatus.RESTRICTED),
    );
    const insufficientData = discount.daily.filter(
        (item) =>
            item.status === 'INSUFFICIENT_DATA' ||
            (item.status === 'GRANTED' &&
                !skipAgreementRule &&
                discount.monthlyRestrictionStatus[format(parse(item.date, 'yyyy-MM-dd', new Date()), 'yyyy-MM')] ===
                    RestrictionStatus.UNKNOWN),
    );
    const amountGranted = selectedDiscounts.reduce((accumulator, item) => {
        return accumulator + Math.round(item.amount * 100) / 100;
    }, 0);

    const selectedDiscountsGroupByMonth = groupBy(selectedDiscounts, (item) => item.date.slice(0, -3));

    const amountGrantedByMonth: { [key: string]: number } = Object.keys(selectedDiscountsGroupByMonth).reduce(
        (r, v) => {
            return Object.assign(r, {
                [v]: selectedDiscountsGroupByMonth[v].reduce((accumulator, item) => {
                    return Math.round((accumulator + item.amount) * 100) / 100;
                }, 0),
            });
        },
        {},
    );

    const inArray = (daily: Daily[], date: Date) =>
        daily.findIndex((d) => isEqual(parse(d.date, 'yyyy-MM-dd', new Date()), date)) !== -1;

    const showDatesAsDateRange = (
        daily: Daily[] | SelectedDiscountData[],
        prefix: string,
        checkedDataArray: Daily[],
        isInArrayCheck: boolean,
    ) => {
        const dateArr = daily.map((item) => parse(item.date, 'yyyy-MM-dd', new Date()));
        const newArr = [...dateArr].sort((a, b) => a.getTime() - b.getTime());
        let startPoint: Date | null = null;
        let prevDate: Date | null = null;

        const getValue = (value: boolean, isNegated: boolean) => (isNegated ? !value : value);

        return newArr.map((date, index, array) => {
            const lastDate = !(array.length > index + 1);
            let result: string | null = null;
            let resultLast: string | null = null;
            let showIcon: boolean | null = null;
            let showIconLast: boolean | null = null;
            if (startPoint && prevDate) {
                if (
                    isEqual(addDays(prevDate, 1), date) &&
                    ((inArray(checkedDataArray, date) && inArray(checkedDataArray, prevDate)) ||
                        (!inArray(checkedDataArray, date) && !inArray(checkedDataArray, prevDate)))
                ) {
                    prevDate = date;
                    if (lastDate) {
                        if (isEqual(startPoint, prevDate)) {
                            result = `${format(startPoint, 'dd.MM.yyyy')}`;
                            showIcon = getValue(inArray(checkedDataArray, startPoint), !isInArrayCheck);
                        } else {
                            result = `${format(startPoint, 'dd.MM.yyyy')} - ${format(prevDate, 'dd.MM.yyyy')}`;
                            showIcon = getValue(inArray(checkedDataArray, prevDate), !isInArrayCheck);
                        }
                    }
                } else {
                    if (isEqual(startPoint, prevDate)) {
                        result = `${format(startPoint, 'dd.MM.yyyy')}`;
                        showIcon = getValue(inArray(checkedDataArray, startPoint), !isInArrayCheck);
                    } else {
                        result = `${format(startPoint, 'dd.MM.yyyy')} - ${format(prevDate, 'dd.MM.yyyy')}`;
                        showIcon = getValue(inArray(checkedDataArray, prevDate), !isInArrayCheck);
                    }

                    if (lastDate) {
                        resultLast = `${format(date, 'dd.MM.yyyy')}`;
                        showIconLast = getValue(inArray(checkedDataArray, date), !isInArrayCheck);
                    }

                    prevDate = date;
                    startPoint = date;
                }
            } else {
                if (lastDate) {
                    result = `${format(date, 'dd.MM.yyyy')}`;
                    showIcon = getValue(inArray(checkedDataArray, date), !isInArrayCheck);
                }
                startPoint = date;
                prevDate = date;
            }

            if (result !== null && showIcon !== null) {
                return (
                    <React.Fragment key={`${prefix}-${result.replace(' ', '')}`}>
                        <View>{renderDate(result, showIcon)}</View>
                        {resultLast && showIconLast !== null && <View>{renderDate(resultLast, showIconLast)}</View>}
                    </React.Fragment>
                );
            }
        });
    };

    const renderDate = (date: string, showIcon: boolean) => (
        <>
            {showIcon ? (
                <View style={styles.flex}>
                    <Text style={styles.date}>{date}</Text>
                    <IconInfo />
                </View>
            ) : (
                <Text style={styles.date}>{date}</Text>
            )}
        </>
    );

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.h1}>Podsumowanie</Text>
                    <Text style={styles.text}>Klient: {`${clientName} ${clientAddress}`}</Text>
                    <Text style={styles.text}>Nr reklamacji: {complaintNumber}</Text>
                </View>
                <View style={styles.consumptionPointBox}>
                    <View style={styles.topContainer}>
                        <View style={styles.pointAddress}>
                            <Text style={styles.text1}>Adres punktu odbioru</Text>
                            <Text style={styles.text2}>{consumptionPoint.name}</Text>
                        </View>
                        <View style={styles.substationAddress}>
                            <View>
                                <Text style={styles.text1}>Adres i kod węzła</Text>
                                <Text style={styles.text2}>
                                    {consumptionPoint.substationName} ({consumptionPoint.substationValue})
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.bottomContainer}>
                        <View style={styles.systemCol}>
                            <Text style={styles.text3}>Dni zgłoszone przez klienta i zaklasyfikowane przez system</Text>
                            <View style={styles.systemContainer}>
                                <View style={styles.col}>
                                    <Text style={styles.text5}>Dni uznane</Text>
                                    {granted.length ? (
                                        showDatesAsDateRange(granted, 'accepted', [], true)
                                    ) : (
                                        <Text style={styles.text4}>-</Text>
                                    )}
                                </View>
                                <View style={styles.col}>
                                    <Text style={styles.text5}>Dni nieuznane</Text>
                                    {notGranted.length ? (
                                        showDatesAsDateRange(notGranted, 'notAccepted', grantedByStatus, true)
                                    ) : (
                                        <Text style={styles.text4}>-</Text>
                                    )}
                                </View>
                                <View style={styles.colLast}>
                                    <Text style={styles.text5}>Brak danych z dni</Text>
                                    {insufficientData.length ? (
                                        showDatesAsDateRange(insufficientData, 'noData', grantedByStatus, true)
                                    ) : (
                                        <Text style={styles.text4}>-</Text>
                                    )}
                                </View>
                            </View>
                        </View>
                        <View style={styles.userCol}>
                            <Text style={styles.text3}>Zatwierdzone przez pracownika</Text>
                            <Text style={styles.text5}>Dni uznane</Text>
                            {selectedDiscounts.length ? (
                                showDatesAsDateRange(selectedDiscounts, 'userSelected', granted, false)
                            ) : (
                                <Text style={styles.text4}>-</Text>
                            )}
                        </View>
                        <View style={styles.summaryCol}>
                            <Text style={styles.text3}>&nbsp;</Text>
                            <Text style={styles.text6}>Wynik kalkulacji</Text>
                            <View>
                                <View style={styles.status}>
                                    {amountGranted ? <IconAccept /> : <IconReject />}
                                    <Text style={styles.text5}>
                                        {amountGranted ? 'Bonifikata naliczona' : 'Bonifikata odrzucona'}
                                    </Text>
                                </View>
                                {!!insufficientData.length && (
                                    <View style={styles.status}>
                                        <IconError />
                                        <Text style={styles.text5}>Brak danych w systemie</Text>
                                    </View>
                                )}
                                {!!amountGranted &&
                                    Object.keys(amountGrantedByMonth).map((key: string) => (
                                        <Text key={key} style={styles.monthAmount}>
                                            {`${months[parse(key, 'yyyy-MM', new Date()).getMonth()]}  `}
                                            {`${(Math.round(amountGrantedByMonth[key] * 100) / 100)
                                                .toFixed(2)
                                                .toString()
                                                .replace('.', ',')} zł`}
                                        </Text>
                                    ))}
                            </View>
                        </View>
                    </View>
                </View>
                <View style={[styles.totalBox, amountGranted ? styles.accepted : styles.rejected]}>
                    <View style={styles.rowContainer}>
                        <View style={styles.colTotal}>
                            <Text style={styles.summaryText}>Suma bonifikaty</Text>
                        </View>
                        <View style={styles.colTotal}>
                            <View style={styles.status}>
                                {amountGranted ? <IconAccept /> : <IconReject />}
                                <Text style={styles.text5}>
                                    {amountGranted ? 'Bonifikata naliczona' : 'Bonifikata odrzucona'}
                                </Text>
                            </View>
                            <Text style={styles.amount}>
                                {`${(Math.round(amountGranted * 100) / 100)
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')} zł`}
                            </Text>
                        </View>
                    </View>
                    {!!insufficientData.length && <Notification />}
                </View>
            </Page>
        </Document>
    );
};

const IconAccept = () => (
    <Svg width="9" height="9" viewBox="0 0 24 24" style={styles.icon}>
        <Circle cx="12" cy="12" r="12" fill="#0F7A5A" />
        <Path
            d="M16.875 7L10.35 14.5L7.125 10.75L6 12L9.3 15.75L9.375 15.8333L10.35 17L18 8.25L16.875 7Z"
            fill="white"
        />
    </Svg>
);

const IconReject = () => (
    <Svg width="9" height="9" viewBox="0 0 24 24" style={styles.icon}>
        <Circle cx="12" cy="12" r="12" fill="#BF4F2A" />
        <Path
            d="M17 8.04478L15.9552 7L12 10.9552L8.04478 7L7 8.04478L10.9552 12L7 15.9552L8.04478 17L12 13.0448L15.9552 17L17 15.9552L13.0448 12L17 8.04478Z"
            fill="white"
        />
    </Svg>
);

type IconErrorProps = {
    fill?: string;
};

const IconError: FunctionComponent<IconErrorProps> = ({ fill }) => (
    <Svg width="9" height="7.875" viewBox="0 0 24 21" style={styles.icon}>
        <Path
            d="M12 0L0 21H24L12 0ZM13.2 17.5H10.8V15.1667H13.2V17.5ZM10.8 14V8.16667H13.2V14H10.8Z"
            fill={fill ? fill : '#BF4F2A'}
        />
    </Svg>
);

const IconInfo = () => (
    <Svg width="7" height="7" viewBox="0 0 24 24">
        <Path
            d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 2c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8zm1 5V7h-2v2h2zm0 8v-6h-2v6h2z"
            fill="#406AAD"
        />
    </Svg>
);

const notificationStyles = StyleSheet.create({
    notificationContainer: {
        marginTop: 14,
        display: 'flex',
        flexDirection: 'row',
    },
    leftLine: {
        width: 3,
        backgroundColor: '#BF4F2A',
    },
    mainContent: {
        flex: 1,
        backgroundColor: '#ffffff',
        padding: 10,
    },
    notificationText: {
        display: 'flex',
        flexDirection: 'row',
    },
    text: {
        fontSize: 7,
        color: '#41414a',
    },
});

const Notification = () => (
    <View style={notificationStyles.notificationContainer}>
        <View style={notificationStyles.leftLine}></View>
        <View style={notificationStyles.mainContent}>
            <View style={notificationStyles.notificationText}>
                <IconError fill={'#333333'} />
                <Text style={notificationStyles.text}>
                    W wyliczonej bonifikacie nie zostały uwzględnione dni z brakującymi danymi w systemie
                </Text>
            </View>
        </View>
    </View>
);

export default Pdf;
