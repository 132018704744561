import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getDiscount } from 'src/api/requests';
import { Discount } from 'src/api/model/Discount';

import { DiscountParams } from './AppStepper.types';

const fetchDiscount = createAsyncThunk('discount/get', async (param: DiscountParams): Promise<Discount> => {
    const { substationId, consumptionPointId, startDate, endDate } = param;
    return await getDiscount(substationId, consumptionPointId, startDate, endDate);
});

const revertAll = createAction('REVERT_ALL');

export { fetchDiscount, revertAll };
