import React, { FunctionComponent, useCallback } from 'react';
import { styles as elStyles, colors } from '@fortum/elemental-ui';

import { AutoCompleteItem } from 'src/components/AutoComplete/AutoComplete.types';

import styles from './ListItems.module.scss';

type ListItemProps = {
    active: boolean;
    activeRef?: React.RefObject<HTMLLIElement>;
    ariaPosinset: number;
    ariaSetsize: number;
    delay: number;
    id?: string;
    item: AutoCompleteItem;
    onSelect: (value: string) => void;
    onMouseMove: (value: string) => void;
    tabIndex: number;
};

export const ListItem: FunctionComponent<ListItemProps> = ({
    activeRef,
    active,
    ariaPosinset,
    ariaSetsize,
    delay,
    id = '',
    item,
    onSelect,
    onMouseMove,
    tabIndex,
}: ListItemProps) => {
    const { value, name } = item;
    const handleMouseMove = useCallback(() => onMouseMove(value), [onMouseMove, value]);
    const handleClick = useCallback(() => onSelect(value), [onSelect, value, activeRef]);
    return (
        <li
            data-test={'elemental-ListItems-'.concat(value)}
            onMouseMove={handleMouseMove}
            onClick={handleClick}
            ref={activeRef}
            aria-selected={undefined}
            role="option"
            id={id}
            tabIndex={tabIndex}
            aria-posinset={ariaPosinset}
            aria-setsize={ariaSetsize}
            onSelect={() => onSelect(value)}
            className={styles.item}
            style={{
                fontFamily: elStyles.fontFamily.base,
                transition: `opacity ${elStyles.transition.timing.faster},transform ${elStyles.transition.timing.faster}`,
                transitionTimingFunction: elStyles.transition.function.base,
                transitionDelay: `${delay}ms`,
                backgroundColor: active ? colors.lightGrey : undefined,
            }}
        >
            <label>
                <span>{value}</span>
                <span>{name}</span>
            </label>
        </li>
    );
};
