import { Discount } from 'src/api/model/Discount';
import { DateRange } from 'src/containers/ConsumptionPointDatesInput/ConsumptionPointDatesInput.types';
import { SelectedDiscountData } from 'src/containers/CalculationDetailsModal/CalculationDetailsModal.types';
import { ConsumptionPointItem } from 'src/containers/SecondStep/SecondStep.types';

export type ConsumptionPointSummaryProps = {
    clientName: string;
    discount: Discount;
    dateRangeArr: DateRange[];
    consumptionPoint: ConsumptionPointItem;
    selectedDiscounts: SelectedDiscountData[];
    setSelectedDiscounts: (value: SelectedDiscountData[]) => void;
    skipAgreementRule: boolean;
};

export enum PopoverVariant {
    INSUFFICIENT_DATA = 'INSUFFICIENT_DATA',
    NOT_GRANTED = 'NOT_GRANTED',
    USER_MODIFIED = 'USER_MODIFIED',
}
