import { createSlice } from '@reduxjs/toolkit';

import { fetchDailyDataList } from './ConsumptionPointDatesInput.thunk';
import { DailyDataState } from './ConsumptionPointDatesInput.types';

const dailyDataListInitialState: DailyDataState = {
    dailyDataList: [],
    loading: 'idle',
};

const dailyDataListSlice = createSlice({
    name: 'dailyDataList',
    initialState: dailyDataListInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchDailyDataList.pending, (state) => {
            state.loading = 'pending';
        });
        builder.addCase(fetchDailyDataList.fulfilled, (state, action) => {
            state.loading = 'succeeded';
            if (action.payload) {
                state.dailyDataList = action.payload;
            }
        });
        builder.addCase(fetchDailyDataList.rejected, (state) => {
            state.loading = 'failed';
        });
    },
});

const dailyDataListReducer = dailyDataListSlice.reducer;

export { dailyDataListReducer };
