import React, { forwardRef, FunctionComponent, useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'date-fns/isEqual';
import isSameMonth from 'date-fns/isSameMonth';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import subMonths from 'date-fns/subMonths';
import subYears from 'date-fns/subYears';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import pl from 'date-fns/locale/pl';
import {
    Checkbox,
    ContentText,
    IconCalendarUI,
    IconError,
    InputField,
    InputFieldProps,
    Link,
    Loader,
} from '@fortum/elemental-ui';
import { AppDispatch, RootState } from 'src/providers/store';

import { fetchDailyDataList } from './ConsumptionPointDatesInput.thunk';
import { DateRange } from './ConsumptionPointDatesInput.types';

import './ConsumptionPointDatesInput.css';
import styles from './ConsumptionPointDatesInput.module.scss';

registerLocale('pl', pl);

type ConsumptionPointDatesInputProps = {
    index: string;
    pointAddress: string;
    pointId: string;
    substationAddress: string;
    substationId: string;
    dateRangeArr: DateRange[];
    setDateRangeArr: React.Dispatch<React.SetStateAction<{ startDate: Date | null; endDate: Date | null }[]>>;
    onRemove: () => void;
    skipAgreementRule: boolean;
    toggleAgreementRule: () => void;
};

const showRangeLink = false;

const ConsumptionPointDatesInput: FunctionComponent<ConsumptionPointDatesInputProps> = ({
    index,
    pointAddress,
    pointId,
    substationAddress,
    substationId,
    dateRangeArr,
    setDateRangeArr,
    onRemove,
    skipAgreementRule,
    toggleAgreementRule,
}: ConsumptionPointDatesInputProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const maxDate = new Date();
    const minDate = subYears(maxDate, 3);
    const [currentDataEndDate, setCurrentDataEndDate] = useState<Date>(maxDate);
    const dailyDataState = useSelector((state: RootState) => state.dailyDatas);

    const benefitDates = dailyDataState.dailyDataList
        .filter((item) => item.heatingSupplyRestriction)
        .map((item) => parse(item.date, 'yyyy-MM-dd', maxDate));

    const noDataDates = dailyDataState.dailyDataList
        .filter((item) => item.heatingSupplyRestriction === null)
        .map((item) => parse(item.date, 'yyyy-MM-dd', maxDate));

    const fetchDailyData = (substationId: string, startDate: Date, endDate: Date) => {
        setCurrentDataEndDate(endDate);
        dispatch(
            fetchDailyDataList({
                substationId: substationId,
                startDate: format(isSameMonth(startDate, minDate) ? minDate : startDate, 'yyyy-MM-dd'),
                endDate: format(isSameMonth(endDate, maxDate) ? maxDate : endDate, 'yyyy-MM-dd'),
            }),
        );
    };

    useEffect(() => {
        const startDate = startOfMonth(subMonths(maxDate, 1));
        fetchDailyData(substationId, startDate, maxDate);
    }, []);

    const addDateRange = () =>
        setDateRangeArr((prevState: DateRange[]) => {
            return [...prevState, { startDate: null, endDate: null }];
        });

    const removeDateRange = (index: number) =>
        setDateRangeArr((prevState) => {
            const state = prevState.slice();
            state.splice(index, 1);
            return state;
        });

    const onChange = (dates: [Date | null, Date | null], i: number) => {
        const [start, end] = dates;
        const index = i;
        setDateRangeArr((prevState: DateRange[]) => {
            const state = prevState.slice();
            state[index].startDate = start;
            state[index].endDate = end;
            return state;
        });
    };

    const onMonthChange = (date: Date) => {
        const endDate = endOfMonth(date);
        const startDate = startOfMonth(subMonths(endDate, 1));
        fetchDailyData(substationId, startDate, endDate);
    };

    const CustomInput = forwardRef<HTMLInputElement, InputFieldProps>((props: InputFieldProps, ref) => (
        <InputField {...props} inputRef={ref} />
    ));
    CustomInput.displayName = 'CustomInput';

    return (
        <div className={styles.mainContainer}>
            <div className={styles.indexContainer}>
                <ContentText size="m" lineHeight={1.2}>
                    {`${index}.`}
                </ContentText>
            </div>
            <div className={styles.dataContainer}>
                <div className={styles.topContainer}>
                    <div className={styles.pointAddress}>
                        <ContentText size="s" lineHeight={1.2}>
                            Adres punktu odbioru
                        </ContentText>
                        <ContentText size="xl" lineHeight={1.2}>
                            {`${pointAddress} (${pointId})`}
                        </ContentText>
                    </div>
                    <div className={styles.substationAddress}>
                        <div>
                            <ContentText size="s" lineHeight={1.2}>
                                Adres i kod węzła
                            </ContentText>
                            <ContentText size="xl" lineHeight={1.2}>
                                {`${substationAddress} (${substationId})`}
                            </ContentText>
                        </div>
                        <div className={styles.delete}>
                            <Link onClick={() => onRemove()}>Usuń</Link>
                        </div>
                    </div>
                </div>
                <div className={styles.bottomContainer}>
                    <div className={styles.addressItem}>
                        <ContentText size="s" lineHeight={1.2}>
                            Rodzaj umowy
                        </ContentText>
                        <Checkbox
                            name="type"
                            label="Pomiń warunek umowny (ilość i długość ograniczeń)"
                            checked={skipAgreementRule}
                            onChange={toggleAgreementRule}
                        />
                    </div>
                    <div className={styles.addressItem}>
                        <ContentText size="s" lineHeight={1.2}>
                            Zakres dat
                        </ContentText>

                        {dateRangeArr.map((item, i) => (
                            <div key={`dateRangeFragment-${i}`} className={styles.datepickerContainer}>
                                <DatePicker
                                    calendarContainer={({ className, children, showPopperArrow, arrowProps = {} }) => {
                                        return (
                                            <div className={className}>
                                                {showPopperArrow && (
                                                    <div className="react-datepicker__triangle" {...arrowProps} />
                                                )}
                                                {dailyDataState.loading === 'pending' && (
                                                    <div className="react-datepicker__loader">
                                                        <Loader size={24} />
                                                    </div>
                                                )}
                                                {dailyDataState.loading === 'failed' && (
                                                    <div className="react-datepicker__loader">
                                                        <IconError color={'#BF4F2A'} size={48} />
                                                        <ContentText className="title" color={'#41414A'}>
                                                            Błąd wyświetlania
                                                        </ContentText>
                                                        <ContentText color={'#777'} size={16}>
                                                            Nie udało się wczytać kalendarza
                                                        </ContentText>
                                                        <Link
                                                            size="s"
                                                            onClick={() =>
                                                                fetchDailyData(
                                                                    substationId,
                                                                    startOfMonth(subMonths(currentDataEndDate, 1)),
                                                                    currentDataEndDate,
                                                                )
                                                            }
                                                        >
                                                            Spróbuj ponownie
                                                        </Link>
                                                    </div>
                                                )}
                                                {dailyDataState.loading === 'succeeded' && (
                                                    <>
                                                        {children}
                                                        <div className="react-datepicker__footer">
                                                            <ul className="react-datepicker__legend">
                                                                <li className="react-datepicker__legend-item">
                                                                    <span className="legend dot"></span>
                                                                    <span>Należy się bonifikata</span>
                                                                </li>
                                                                <li className="react-datepicker__legend-item">
                                                                    <span className="legend square"></span>
                                                                    <span>Brak danych</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        );
                                    }}
                                    customInput={
                                        <CustomInput
                                            name={`dateRange-${i}`}
                                            label={'Zakres dat'}
                                            className={styles.datepicker}
                                            rightIcon={<IconCalendarUI />}
                                        />
                                    }
                                    dateFormat={'dd.MM.yyyy'}
                                    endDate={item.endDate ? item.endDate : undefined}
                                    locale="pl"
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    onChange={(dates) => onChange(dates, i)}
                                    onCalendarOpen={() => {
                                        if (item.startDate && !isSameMonth(currentDataEndDate, item.startDate)) {
                                            const startDate = startOfMonth(subMonths(item.startDate, 1));
                                            const endDate = endOfMonth(item.startDate);
                                            fetchDailyData(substationId, startDate, endDate);
                                        } else if (!item.startDate && !isSameMonth(currentDataEndDate, maxDate)) {
                                            const startDate = startOfMonth(subMonths(maxDate, 1));
                                            fetchDailyData(substationId, startDate, maxDate);
                                        }
                                    }}
                                    onMonthChange={(date) => {
                                        onMonthChange(date);
                                    }}
                                    popperModifiers={[
                                        {
                                            name: 'flip',
                                            enabled: false,
                                        },
                                    ]}
                                    popperPlacement={'bottom'}
                                    selectsRange={true}
                                    shouldCloseOnSelect={false}
                                    startDate={item.startDate ? item.startDate : undefined}
                                    renderDayContents={(day, date) => {
                                        if (date && benefitDates.some((d) => isEqual(d, date))) {
                                            return (
                                                <>
                                                    <span>{day}</span>
                                                    <span className="dot"></span>
                                                </>
                                            );
                                        } else if (date && noDataDates.some((d) => isEqual(d, date))) {
                                            return (
                                                <>
                                                    <span>{day}</span>
                                                    <span className="square"></span>
                                                </>
                                            );
                                        }
                                        return <span>{day}</span>;
                                    }}
                                />
                                {i > 0 && <Link onClick={() => removeDateRange(i)}>Usuń</Link>}
                            </div>
                        ))}

                        {showRangeLink && <Link onClick={addDateRange}>Dodaj kolejny zakres</Link>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConsumptionPointDatesInput;
