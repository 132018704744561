const baseURL = `${window.location.protocol}//${window.location.host}`;

const clientID = process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID ? process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID : '';

const origin = process.env.REACT_APP_USER_POOL_ORIGIN;

export const logedPath = '/loged';
export const logoutedPath = '/logouted';

export const loginURL = `${origin}/login?client_id=${encodeURIComponent(
    clientID,
)}&response_type=token&scope=email+openid+profile&redirect_uri=${encodeURIComponent(baseURL + logedPath)}`;

export const logoutURL = `${origin}/logout?client_id=${encodeURIComponent(clientID)}&logout_uri=${encodeURIComponent(
    baseURL + logoutedPath,
)}`;
