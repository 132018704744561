import { createAsyncThunk } from '@reduxjs/toolkit';
import { ConsumptionPoint } from 'src/api/model/ConsumptionPoint';
import { getConsumptionPoints } from 'src/api/requests';

const fetchConsumptionPointList = createAsyncThunk(
    'consumptionPointList/get',
    async (clientNumber: string): Promise<ConsumptionPoint[]> => {
        return await getConsumptionPoints(clientNumber);
    },
);

export { fetchConsumptionPointList };
