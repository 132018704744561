import { AxiosResponse } from 'axios';

interface DownloadFileProps {
    readonly apiDefinition: () => Promise<AxiosResponse<Blob>>;
    readonly preDownloading: () => void;
    readonly postDownloading: () => void;
    readonly onError: () => void;
    readonly getFileName: () => string;
}

interface DownloadedFileInfo {
    readonly download: () => Promise<void>;
}

const forceDownload = (fileName: string, blobUrl: string) => {
    const anchor = document.createElement('a');
    anchor.href = blobUrl;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    anchor.remove();
};

export const useDownloadFile = ({
    apiDefinition,
    preDownloading,
    postDownloading,
    onError,
    getFileName,
}: DownloadFileProps): DownloadedFileInfo => {
    const download = async () => {
        try {
            preDownloading();
            const { data } = await apiDefinition();
            const url = URL.createObjectURL(new Blob([data]));
            forceDownload(getFileName(), url);
            postDownloading();
            URL.revokeObjectURL(url);
        } catch (error) {
            onError();
        }
    };

    return { download };
};
