import React, { FunctionComponent } from 'react';

import styles from './IconSuccess.module.scss';
import { IconSuccessProps } from './IconSuccess.types';

const IconSuccess: FunctionComponent<IconSuccessProps> = ({ className, fill }: IconSuccessProps) => {
    return (
        <span className={`${styles.container} ${className ? className : ''}`}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M18.5 6L9.8 15L5.5 10.5L4 12L8.4 16.5L8.5 16.6L9.8 18L20 7.5L18.5 6Z"
                    fill={fill ? fill : '#333333'}
                />
            </svg>
        </span>
    );
};

export default IconSuccess;
