import { Button, ContentText, Heading, Stepper, useToggle, useNumber } from '@fortum/elemental-ui';
import { PDFDownloadLink } from '@react-pdf/renderer';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';

import FirstStep from 'src/containers/FirstStep/FirstStep';
import SecondStep from 'src/containers/SecondStep/SecondStep';
import ThirdStep from 'src/containers/ThirdStep/ThirdStep';
import Pdf from 'src/components/Pdf/Pdf';
import { AppDispatch, RootState } from 'src/providers/store';
import { ConsumptionPointItem } from 'src/containers/SecondStep/SecondStep.types';
import { SelectedDiscountData } from 'src/containers/CalculationDetailsModal/CalculationDetailsModal.types';
import { DateRange } from 'src/containers/ConsumptionPointDatesInput/ConsumptionPointDatesInput.types';

import { fetchDiscount, revertAll } from './AppStepper.thunk';

import styles from './AppStepper.module.scss';

const AppStepper: FunctionComponent = () => {
    const initDateRangeArr: DateRange[] = [
        {
            startDate: null,
            endDate: null,
        },
    ];
    const dispatch = useDispatch<AppDispatch>();
    const [clientName, setClientName] = useState<string>('');
    const [clientNumber, setClientNumber] = useState<string>('');
    const [clientAddress, setClientAddress] = useState<string>('');
    const [complaintNumber, setComplaintNumber] = useState<string>('');
    const [selectedItem, setSelectedItem] = useState<ConsumptionPointItem | null>(null);
    const [dateRangeArr, setDateRangeArr] = useState(initDateRangeArr);
    const [selectedDiscounts, setSelectedDiscounts] = useState<SelectedDiscountData[]>([]);
    const prevSelectedItemRef = useRef<ConsumptionPointItem | null>(null);
    const discountState = useSelector((state: RootState) => state.discountState);
    const [reportView, setReportView] = useState<boolean>(false);
    const [skipAgreementRule, toggleAgreementRule] = useToggle(false);
    const { value: active, increment: nextStep, decrement: prevStep } = useNumber(1);

    const onSelectFirstStepItem = (value: string, name: string, address: string) => {
        setClientName(name);
        setClientNumber(value);
        setClientAddress(address);
        setSelectedItem(null);
        setDateRangeArr(initDateRangeArr);
        skipAgreementRule && toggleAgreementRule();
    };

    const onChangeFirstStepInput = (value: string) => {
        setComplaintNumber(value);
    };

    useEffect(() => {
        if (prevSelectedItemRef.current !== selectedItem) {
            dispatch(revertAll());
        }
        prevSelectedItemRef.current = selectedItem;
    }, [selectedItem]);

    const getDiscount = () => {
        const startDate = dateRangeArr.length && dateRangeArr[0].startDate ? dateRangeArr[0].startDate : null;
        const endDate = dateRangeArr.length && dateRangeArr[0].endDate ? dateRangeArr[0].endDate : null;
        if (selectedItem && startDate && endDate) {
            dispatch(
                fetchDiscount({
                    substationId: selectedItem.substationValue,
                    consumptionPointId: selectedItem.value,
                    startDate: format(startDate, 'yyyy-MM-dd'),
                    endDate: format(endDate, 'yyyy-MM-dd'),
                }),
            );
        }
    };

    const stepsBase = [
        {
            id: 1,
            header: 'Parametry reklamacji',
            content: (
                <FirstStep
                    clientNumber={clientNumber}
                    complaintNumber={complaintNumber}
                    onSelectAutoCompleteItem={onSelectFirstStepItem}
                    onChangeInputField={onChangeFirstStepInput}
                />
            ),
        },
        {
            id: 2,
            header: 'Punkty odbioru',
            content: (
                <SecondStep
                    clientNumber={clientNumber}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    dateRangeArr={dateRangeArr}
                    setDateRangeArr={setDateRangeArr}
                    skipAgreementRule={skipAgreementRule}
                    toggleAgreementRule={toggleAgreementRule}
                />
            ),
        },
        {
            id: 3,
            header: 'Wynik kalkulacji',
            content: selectedItem && (
                <ThirdStep
                    clientName={clientName}
                    clientAddress={clientAddress}
                    complaintNumber={complaintNumber}
                    consumptionPoint={selectedItem}
                    dateRangeArr={dateRangeArr}
                    discountState={discountState}
                    getDiscount={getDiscount}
                    selectedDiscounts={selectedDiscounts}
                    setSelectedDiscounts={setSelectedDiscounts}
                    skipAgreementRule={skipAgreementRule}
                />
            ),
        },
    ];

    const steps = stepsBase.map((step, index) => ({
        ...step,
        valid: active > index + 1,
        inactive: active !== index + 1,
    }));

    const nextDisabled =
        (active === 1 && !(clientNumber && complaintNumber)) ||
        (active === 2 &&
            !(selectedItem && dateRangeArr.length && dateRangeArr[0].startDate && dateRangeArr[0].endDate));

    const generateReportDisabled = discountState.loading !== 'succeeded';

    return (
        <div className={styles.pageLayout}>
            {reportView ? (
                <>
                    <div>
                        <Heading className={styles.h1} lineHeight={1.2} level={1} styledAs={4} textAlign="left">
                            Raport dla bonifikaty
                        </Heading>
                        <div className={styles.content}>
                            <ContentText size="m">Klient: {`${clientName} ${clientAddress}`}</ContentText>
                            <ContentText size="m">Nr reklamacji: {complaintNumber}</ContentText>
                            {discountState.discount && selectedItem && (
                                <div className={styles.getReport}>
                                    <PDFDownloadLink
                                        className={styles.secondaryButton}
                                        document={
                                            <Pdf
                                                clientName={clientName}
                                                clientAddress={clientAddress}
                                                complaintNumber={complaintNumber}
                                                consumptionPoint={selectedItem}
                                                discount={discountState.discount}
                                                selectedDiscounts={selectedDiscounts}
                                                skipAgreementRule={skipAgreementRule}
                                            />
                                        }
                                        fileName={'Raport.pdf'}
                                    >
                                        {({ loading }) => (loading ? 'Loading...' : 'Pobierz raport')}
                                    </PDFDownloadLink>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={`${styles.bottom} ${styles.bottomReport}`}>
                        <Button status="secondary" onClick={() => setReportView(false)}>
                            {'Wstecz'}
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <div>
                        <Heading className={styles.h1} lineHeight={1.2} level={1} styledAs={4} textAlign="center">
                            Sprawdź bonifikatę niedotrzymania parametrów dostawy ciepła
                        </Heading>
                        <Stepper orientation="horizontal" steps={steps} />
                    </div>
                    <div className={styles.bottom}>
                        {active !== 3 ? (
                            <Button status="primary" disabled={nextDisabled} onClick={nextStep}>
                                Dalej
                            </Button>
                        ) : (
                            <Button
                                status="primary"
                                disabled={generateReportDisabled}
                                onClick={() => setReportView(true)}
                            >
                                Zatwierdź i wygeneruj raport
                            </Button>
                        )}
                        {active !== 1 && (
                            <Button status="secondary" onClick={prevStep}>
                                {active !== 3 ? 'Wstecz' : 'Wróć do edycji'}
                            </Button>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default AppStepper;
