export enum RestrictionStatus {
    RESTRICTED = 'RESTRICTED',
    NOT_RESTRICTED = 'NOT_RESTRICTED',
    UNKNOWN = 'UNKNOWN',
}

export type Discount = {
    daily: Daily[];
    monthlyRestrictionStatus: {
        [key: string]: RestrictionStatus;
    };
};

export type Daily = {
    date: string;
    status: string;
    value: number;
};
