import React, { FunctionComponent } from 'react';
import { Box, LogoWidget } from '@fortum/elemental-ui';
import { LogoutIcon } from 'src/components/CustomIcon/CustomIcon';
import { logoutURL } from 'src/auth';

import styles from './Header.module.scss';

const Header: FunctionComponent = () => {
    return (
        <div className={styles.HeaderContainer}>
            <Box width="1280px" flex="1 0 auto" margin="0 auto" padding="0 2rem" backgroundColor="#377A6C">
                <div className={styles.HeaderContent}>
                    <div className={styles.HeaderLeft}>
                        <LogoWidget />
                    </div>
                    <div className={styles.HeaderRight}>
                        <div className={styles.AuthWidget}>
                            <button
                                className={styles.AuthButtonWrapper}
                                onClick={() => {
                                    window.location.href = logoutURL;
                                }}
                            >
                                <LogoutIcon className={styles.AuthIcon} />
                            </button>
                        </div>
                    </div>
                </div>
            </Box>
        </div>
    );
};

export default Header;
