import { createSlice } from '@reduxjs/toolkit';

import { fetchDiscountsMonthDetails } from './CalculationDetailsModal.thunk';
import { DiscountsMonthDetailsState } from './CalculationDetailsModal.types';

const discountsMonthDetailsInitialState: DiscountsMonthDetailsState = {
    discountsMonthDetails: null,
    loading: 'idle',
};

const discountsMonthDetailsSlice = createSlice({
    name: 'discountsMonthDetails',
    initialState: discountsMonthDetailsInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchDiscountsMonthDetails.pending, (state) => {
            state.loading = 'pending';
        });
        builder.addCase(fetchDiscountsMonthDetails.fulfilled, (state, action) => {
            state.loading = 'succeeded';
            if (action.payload) {
                state.discountsMonthDetails = action.payload;
            }
        });
        builder.addCase(fetchDiscountsMonthDetails.rejected, (state) => {
            state.loading = 'failed';
        });
    },
});

const discountsMonthDetailsReducer = discountsMonthDetailsSlice.reducer;

export { discountsMonthDetailsReducer };
