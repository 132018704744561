import { Box } from '@fortum/elemental-ui';
import React, { FunctionComponent } from 'react';

import Header from 'src/components/Header/Header';
import AppStepper from 'src/containers/AppStepper/AppStepper';

const Home: FunctionComponent = () => (
    <>
        <Box display="flex" flexDirection="column" minHeight="100vh">
            <Header />
            <Box width="1280px" flex="1 0 auto" margin="0 auto" padding="0 2rem">
                <AppStepper />
            </Box>
        </Box>
    </>
);

export default Home;
