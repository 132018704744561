import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import { store } from './providers/store';
import Pages from './pages';
import './styles/main.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <IntlProvider locale="pl">
                <BrowserRouter>
                    <Pages />
                </BrowserRouter>
            </IntlProvider>
        </Provider>
    </React.StrictMode>,
);
