import { createSlice } from '@reduxjs/toolkit';

import { fetchClientList } from './FirstStep.thunk';
import { ClientsList } from './FirstStep.types';

const clientsListInitialState: ClientsList = {
    clientList: [],
    loading: 'idle',
};

const clientsListSlice = createSlice({
    name: 'clientsList',
    initialState: clientsListInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchClientList.pending, (state) => {
            state.loading = 'pending';
        });
        builder.addCase(fetchClientList.fulfilled, (state, action) => {
            state.loading = 'succeeded';
            if (action.payload) {
                state.clientList = action.payload;
            }
        });
        builder.addCase(fetchClientList.rejected, (state) => {
            state.loading = 'failed';
        });
    },
});

const clientsListReducer = clientsListSlice.reducer;

export { clientsListReducer };
