import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDailyData } from 'src/api/requests';
import { DailyData } from 'src/api/model/DailyData';

import { DailyDataParams } from './ConsumptionPointDatesInput.types';

const fetchDailyDataList = createAsyncThunk(
    'dailyDataList/get',
    async (param: DailyDataParams): Promise<DailyData[] | undefined> => {
        const { substationId, startDate, endDate } = param;
        return await getDailyData(substationId, startDate, endDate);
    },
);

export { fetchDailyDataList };
