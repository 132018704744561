import axios, { AxiosResponse } from 'axios';
import makeRequest, { API_URL } from 'src/api/axiosCore';
import { Client } from 'src/api/model/Client';
import { ConsumptionPoint } from 'src/api/model/ConsumptionPoint';
import { DailyData } from 'src/api/model/DailyData';
import { Discount } from 'src/api/model/Discount';
import { DiscountsMonthDetails } from 'src/api/model/DiscountsMonthDetails';

export const getClients = (): Promise<Client[]> =>
    makeRequest({
        method: 'get',
        url: '/clients',
    });

export const getConsumptionPoints = (clientNumber: string): Promise<ConsumptionPoint[]> =>
    makeRequest({
        method: 'get',
        url: '/consumption-points',
        params: { clientNumber },
    });

export const getDailyData = (substationId: string, startDate: string, endDate: string): Promise<DailyData[]> =>
    makeRequest({
        method: 'get',
        url: `/substation/${substationId}/discount/daily-restrictions`,
        params: { startDate, endDate },
    });

export const getDiscount = (
    substationId: string,
    consumptionPointId: string,
    startDate: string,
    endDate: string,
): Promise<Discount> =>
    makeRequest({
        method: 'get',
        url: `/substation/${substationId}/consumption-point/${consumptionPointId}/discount`,
        params: { startDate, endDate },
    });

export const getDiscountsMonthDetails = (
    substationId: string,
    consumptionPointId: string,
    yearMonth: string,
): Promise<DiscountsMonthDetails> =>
    makeRequest({
        method: 'get',
        url: `/substation/${substationId}/consumption-point/${consumptionPointId}/discount/month-details`,
        params: { yearMonth },
    });

export const getCSVReport = (
    substationId: string,
    consumptionPointId: string,
    selectedDays: string,
    reportDate: string,
): Promise<AxiosResponse<Blob>> =>
    axios.get(`/substation/${substationId}/consumption-point/${consumptionPointId}/discount/generate-report`, {
        responseType: 'blob',
        params: { reportDate, selectedDays },
        baseURL: API_URL,
    });
