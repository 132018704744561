import { ContentText, Heading, IconError, Link, Loader, spacingNumerical } from '@fortum/elemental-ui';
import React, { FunctionComponent, useEffect } from 'react';

import ConsumptionPointSummary from 'src/components/ConsumptionPointSummary/ConsumptionPointSummary';
import TotalDiscount from 'src/components/TotalDiscount/TotalDiscount';
import { ConsumptionPointItem } from 'src/containers/SecondStep/SecondStep.types';
import { SelectedDiscountData } from 'src/containers/CalculationDetailsModal/CalculationDetailsModal.types';
import { DateRange } from 'src/containers/ConsumptionPointDatesInput/ConsumptionPointDatesInput.types';
import { DiscountState } from 'src/containers/AppStepper/AppStepper.types';

import styles from './ThirdStep.module.scss';

type ThirdStepProps = {
    clientName: string;
    clientAddress: string;
    complaintNumber: string;
    consumptionPoint: ConsumptionPointItem;
    dateRangeArr: DateRange[];
    discountState: DiscountState;
    getDiscount: () => void;
    selectedDiscounts: SelectedDiscountData[];
    setSelectedDiscounts: (value: SelectedDiscountData[]) => void;
    skipAgreementRule: boolean;
};

const ThirdStep: FunctionComponent<ThirdStepProps> = ({
    clientName,
    clientAddress,
    complaintNumber,
    consumptionPoint,
    dateRangeArr,
    discountState,
    getDiscount,
    selectedDiscounts,
    setSelectedDiscounts,
    skipAgreementRule,
}: ThirdStepProps) => {
    useEffect(() => {
        getDiscount();
    }, []);

    const amountGranted = selectedDiscounts.reduce((accumulator, item) => {
        return accumulator + Math.round(item.amount * 100) / 100;
    }, 0);

    const insufficientData = discountState.discount
        ? discountState.discount.daily.filter((item) => item.status === 'INSUFFICIENT_DATA')
        : [];

    return (
        <div className={styles.content}>
            <Heading
                style={{
                    marginTop: spacingNumerical.m,
                    paddingTop: spacingNumerical.xxxs,
                    marginBottom: spacingNumerical.xs,
                }}
                lineHeight={1.2}
                level={2}
                styledAs={5}
                textAlign="left"
            >
                Podsumowanie
            </Heading>
            <ContentText size="m">Klient: {`${clientName} ${clientAddress}`}</ContentText>
            <ContentText size="m">Nr reklamacji: {complaintNumber}</ContentText>
            {discountState.loading === 'pending' && (
                <div className={styles.centerContainer}>
                    <Loader />
                </div>
            )}
            {discountState.loading === 'succeeded' && discountState.discount && (
                <>
                    <ConsumptionPointSummary
                        clientName={clientName}
                        discount={discountState.discount}
                        consumptionPoint={consumptionPoint}
                        dateRangeArr={dateRangeArr}
                        selectedDiscounts={selectedDiscounts}
                        setSelectedDiscounts={setSelectedDiscounts}
                        skipAgreementRule={skipAgreementRule}
                    />
                    <TotalDiscount amount={amountGranted} noData={!!insufficientData.length} />
                </>
            )}
            {discountState.loading === 'failed' && (
                <div className={styles.centerContainer}>
                    <IconError color={'#BF4F2A'} size={40} />
                    <ContentText color={'#41414A'}>Błąd wyświetlania</ContentText>
                    <ContentText color={'#777'} size={16}>
                        Nie udało się wczytać wyniku kalkulacji. &nbsp;
                        <Link size="s" onClick={() => getDiscount()}>
                            Spróbuj ponownie
                        </Link>
                    </ContentText>
                </div>
            )}
        </div>
    );
};

export default ThirdStep;
